import React, { Component } from "react"
import Footer from "../components/base/Footer"
import Header from "../components/base/Header"
import SEO from "../components/base/seo"
import CardSegment from "../components/marketing/CardSegment"
import LeftContentCard from "../components/marketing/LeftContentCard"
import RightContentCard from "../components/marketing/RightContentCard"
import SubscribeAction from "../components/marketing/SubscribeAction"
import Whatsapp from "../components/marketing/Whatsapp"
import AboutBiodiesel from "../images/about_biodiesel.webp"
import AboutMPOB from "../images/about_mbop.webp"
import AboutNRE from "../images/about_nre.webp"
import CustFactory from "../images/cust_factory.webp"
import CustHouse from "../images/cust_house.webp"
import CustRestaurant from "../images/cust_restaurant.webp"
import DoorImage from "../images/home_ss14.webp"
import StepCollect from "../images/step_collect.webp"
import StepFilter from "../images/step_filter.webp"
import StepLab from "../images/step_lab.webp"
import { Language } from "../store/reducers/utilsReducer"

interface Props {
  authStore: any
  updatePromotionCode: (promoCode: string) => void
}

const workPosts = [
  {
    title: "Step 1 : Collection",
    desc:
      "Door to door service is provided to collect your used cooking oil from residential areas/ factory/ restaurants.Notify us by creating an appoinment in Arus Oil Application Dashboard",
    img: StepCollect,
  },
  {
    title: "Step 2 : Filtration",
    desc:
      "Used cooking oil collected from you will be filtered away from sediments. Common sediments found in this process are food debris",
    img: StepFilter,
  },
  {
    title: "Step 3 : Lab Test",
    desc:
      "Your used cooking oil will be exported to treatment plants for conversion to bio-diesel (Used to power industrial vehicles/ machines )",
    img: StepLab,
  },
]

const customerSegmentPost = [
  {
    title: "Family Household",
    desc:
      "We provide door to door collection service to collect your used cooking oil with a minimum weight of 5kg",
    img: CustHouse,
  },
  {
    title: "Restaurants / Hotels",
    desc:
      "We collect from your restaurant's or hotel's location. Better purchase rates will be given depending on the volume of used cooking oil. Oil barrel for storing used cooking oil may be provided if it is being required",
    img: CustRestaurant,
  },
  {
    title: "Food Factories",
    desc:
      "We collect from your food factory's location. Better purchase rates will be given depending on the volume of used cooking oil.",
    img: CustFactory,
  },
]

class AboutUsPage extends Component<Props> {
  state = {
    visible: false,
    loading: false,
  }

  render() {
    return (
      <div className="bg-white">
        <Whatsapp />
        <SEO
          title="Arus Oil - About Us"
          description="Arus Oil started as a pilot project in SS14,Subang,Klang Valley,Malaysia.The team is actively collecting used cooking oil in Selangor, Negeri Sembilan, Penang and W.P. Kuala Lumpur."
        />
        <Header page="About Us" />
        <LeftContentCard
          image={DoorImage}
          title="About Us - Arus Oil"
          content={
            "Arus Oil (MyProtech Sdn. Bhd.) started as a pilot project for the middle-class in the SS14 community. Since 2017, residents of SS14 have been disposing of their used cooking oil (UCO) in a collection drum at the park nearby. \n\n This project was organised in collaboration with Kawasan Rukun Tetangga (KRT). The survey shows that most of the family households in SS14 cook frequently. Thus, the idea of recycled used cooking oil is born. Instead of throwing your used cooking oil away, you are able to earn cashback by selling it.​"
          }
        />
        <RightContentCard
          image={AboutNRE}
          title="Why Are We Doing This"
          content={
            "From 2017 until today, Arus Oil has collected approximately 250 Metric Tonnes of used cooking oil. The reason why we are continuing the recycling efforts till today is due to the fact of the depletion of non-renewable energy. 72% of our world consumption is powered by fossil fuels (Non-renewable). Petroleum/oil is specifically estimated to be depleted in 2052.\n\n We believe recycling used cooking oil helps to slow down the depletion of non-renewable energy. This is because all of the collected used cooking oil will be converted to bio-diesel which is used to power industrial vehicles or machines.​\n\nArus Oil is actively collecting used cooking oil in Klang Valley (Selangor & Kuala Lumpur - Malaysia).​"
          }
        />
        <CardSegment types={workPosts} title="How Do We Work" />
        <CardSegment types={customerSegmentPost} title="Customer Segments" />
        <LeftContentCard
          image={AboutBiodiesel}
          title="What Happen To Your Used Cooking Oil"
          content={
            "Your used cooking oil will be exported to Netherlands & UK treatment plants where it will be converted to ASTM B-20 Biodiesel grade. There are multiple types of biodiesel which are segregated to \n\n- B-5 grade ( Used in light duty diesel vehicles )\n- B-20 grade ( Used in diesel vehicles or generators )\n- B-100 grade ( Heavy vehicles eg. Trains & aeroplane )"
          }
        />
        <RightContentCard
          image={AboutMPOB}
          contain={true}
          title="Licensed Operator – Arus Oil​"
          content={
            "MPOB License is required to collect/purchase used cooking oil. Used cooking oil ( Palm Oil Based ) is classified as sludge palm oil (SPO) under Malaysian Palm Oil Board Licensing Regulations 2005.​\n\nArus Oil is a licensed operator under MPOB with our license no as 619709017000. Operating with MPOB license ensures all collected used cooking oil are converted to bio-diesel and not for any illegal use that will harm the people and the environment."
          }
        />
        <SubscribeAction language={Language.EN} />
        <Footer />
      </div>
    )
  }
}

export default AboutUsPage
